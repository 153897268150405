<template>
  <div class="min-h-screen bg-gray-100">
    <div class="h-20 bg-gray-800 flex flex-col justify-center">
      <div class="px-4 sm:px-6 lg:px-8 container mx-auto flex flex-row">
        <img
          class="h-8 w-auto"
          src="@/assets/icon-text-cloudsprouter-logo.png"
          alt="Workflow logo"
        />
      </div>
    </div>

    <div class="container mx-auto">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

import axios from "axios";

export default {
  name: "AppLayout",

  data() {
    return {};
  },
};
</script>
